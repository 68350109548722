<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Leerling bewerken
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Vergeet niet je wijzigingen op te slaan
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <!-- Voornaam -->
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.firstName"
                outlined
                dense
                label="Voornaam"
              ></v-text-field>
            </v-col>

            <!-- Achternaam -->
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.lastName"
                outlined
                dense
                label="Achternaam"
              ></v-text-field>
            </v-col>

            <!-- Klas -->
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="userDataLocal.selectedClass"
                :items="classes"
                item-text="title"
                item-value="value"
                outlined
                dense
                label="Klas"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                Opslaan
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Annuleren
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    loginMethods: {
      type: Array,
      required: true,
    },
    classes: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
    userDataLocal.value.selectedClass = userDataLocal.value.class ? userDataLocal.value.class.id : 'none'

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = async () => {
      try {
        console.log(userDataLocal.value)

        // Update account
        const updated = await axios.put(`/account/${props.userData.id}`, userDataLocal.value)
        console.log(updated.data)
        if (!updated.data.success) throw new Error('Could not update account')

        const school = JSON.parse(localStorage.getItem('school'))

        // Update class
        if (userDataLocal.value.selectedClass !== 'none') {
          // If user is already in class, remove from class
          if (props.userData.class) {
            const removedFromClass = await axios.delete(`/school/${school.id}/class/${props.userData.class.id}/pupil/${props.userData.id}`)
            if (!removedFromClass.data.success) throw new Error('Could not remove user from class')
          }

          // Add to class
          const addedToClass = await axios.post(`/school/${school.id}/class/${userDataLocal.value.selectedClass}/pupil/${props.userData.id}`)
          if (!addedToClass.data.success) throw new Error('Could not add user to class')
        }

        // Update login method
        // Not implemented yet
      // Refresh user data
      } catch (error) {
        // toast('error', 'Foutief wachtwoord', 'Probeer het opnieuw')
        console.log(error)
      } finally {
        emit('refetch-data')
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
        userDataLocal.value.selectedClass = userDataLocal.value.class ? userDataLocal.value.class.id : 'none'
      },
    )

    return {
      isBillingAddress,
      statusOptions,
      languageOptions,
      userDataLocal,
      onSubmit,
    }
  },
}
</script>
