<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-2">{{ userData.firstName + ' ' + userData.lastName }}</span>
        </v-card-title>

        <!-- Klas -->
        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiAccountGroup }}
              </v-icon>
            </v-avatar>

            <div>
              <span>{{ userData.class ? userData.class.name : 'Geen' }}</span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3 px-5"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Bewerken
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        :login-methods="loginMethods"
        :classes="classes"
        v-on="$listeners"
      ></user-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import axios from '@axios'
import { avatarText, kFormatter } from '@core/utils/filter'
import {
  mdiAccountGroup, mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isBioDialogOpen = ref(false)

    // Login methods
    const loginMethods = ref([])
    axios.get('/loginmethod').then(response => {
      loginMethods.value = response.data.loginMethods
    })

    const school = JSON.parse(localStorage.getItem('school'))

    // Classes
    const classes = ref([])
    axios.get(`/school/${school.id}/class?simple=true`).then(response => {
      const preparedClasses = response.data.classes.map(classItem => ({
        value: classItem.id,
        title: classItem.name,
      }))
      classes.value = [
        {
          value: 'none',
          title: 'Geen',
          disabled: true,
        },
        ...preparedClasses,
      ]
    })

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      loginMethods,
      classes,

      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccountGroup,
      },
    }
  },
}
</script>
