import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"user-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.userData.firstName + ' ' + _vm.userData.lastName))])]),_c(VCardText,{staticClass:"d-flex justify-center flex-wrap mt-2 pe-sm-0"},[_c('div',{staticClass:"d-flex align-center me-8 mb-4"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountGroup)+" ")])],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.userData.class ? _vm.userData.class.name : 'Geen'))])])],1)]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"me-3 px-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.isBioDialogOpen = !_vm.isBioDialogOpen}}},[_vm._v(" Bewerken ")])],1)],1),_c('user-bio-edit',_vm._g({attrs:{"is-bio-dialog-open":_vm.isBioDialogOpen,"user-data":_vm.userData,"login-methods":_vm.loginMethods,"classes":_vm.classes},on:{"update:isBioDialogOpen":function($event){_vm.isBioDialogOpen=$event},"update:is-bio-dialog-open":function($event){_vm.isBioDialogOpen=$event}}},_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }