import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-view"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('user-bio-panel',{attrs:{"user-data":_vm.userData,"is-plan-upgrade-dialog-visible":_vm.isPlanUpgradeDialogVisible},on:{"update:isPlanUpgradeDialogVisible":function($event){_vm.isPlanUpgradeDialogVisible=$event},"update:is-plan-upgrade-dialog-visible":function($event){_vm.isPlanUpgradeDialogVisible=$event},"refetch-data":_vm.getUserData}})],1),_c(VCol,{attrs:{"cols":"12","md":"7","lg":"8"}},[_c(VTabs,{staticClass:"user-tabs",attrs:{"show-arrows":""},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.icon},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{staticClass:"mt-5 pa-1",attrs:{"id":"user-tabs-content"},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},[_c(VTabItem,[(_vm.userData.id)?_c('user-tab-login-method',{attrs:{"user-data":_vm.userData},on:{"refetch-data":_vm.getUserData}}):_vm._e()],1),_c(VTabItem,[(_vm.userData.id)?_c('component-user-login-details',{attrs:{"user":_vm.userData,"user-type":"Pupil"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }