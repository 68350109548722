<template>
  <div>
    <v-card class="user-tab-notification mb-5">
      <v-card-title>
        Inlogmethode {{ userData.email }}
      </v-card-title>

      <!-- Current login method and dropdown -->
      <v-card-text>
        <p class="mb-1">
          Huidige inlogmethode: {{ (loginMethod.type === 'none' ? 'geen' : loginMethod.type) }}
        </p>
        <p
          v-if="loginMethod.type === 'google' || loginMethod.type === 'microsoft'"
          class="mb-5"
        >
          E-mailadres: {{ loginMethod.info.email }}
        </p>

        <v-select
          v-model="selectedLoginMethod"
          placeholder="Kies inlogmethode"
          :items="allowedLoginMethods"
          item-text="label"
          item-value="type"
          outlined
          dense
          hide-details
          class="me-1 mb-3"
        ></v-select>
      </v-card-text>

      <!-- New login method details -->
      <v-card-text>
        <v-form
          v-if="!!selectedLoginMethod && selectedLoginMethod.type !== 'none'"
          ref="form"
          v-model="formObj.valid"
          lazy-validation
          @submit.prevent="saveLoginMethod"
        >
          <v-divider class="mb-5"></v-divider>
          <h3
            v-if="selectedLoginMethodObj.fields.length > 0"
            class="mb-3 pt-3"
          >
            Benodigde inloggegevens
          </h3>
          <!-- Fields -->
          <v-text-field
            v-for="field in selectedLoginMethodObj.fields"
            :key="field.name"
            v-model="field.value"
            outlined
            :rules="field.rules"
            :label="field.label"
            class="mb-1 pt-2"
            style="margin: 0;"
            required
            dense
          ></v-text-field>

          <v-btn
            color="primary"
            type="submit"
            class="mt-5"
          >
            Opslaan
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions class="caption">
        Door op te slaan worden reeds bestaande gegevens overschreven
      </v-card-actions>
    </v-card>
    <v-card
      v-if="loginMethod.type === 'qr'"
    >
      <v-card-title>
        QR code voor deze gebruiker
      </v-card-title>
      <v-card-text>
        <qr-code
          :text="loginMethod.info.token"
        ></qr-code>
      </v-card-text>
      <v-card-actions>
        <VBtn
          color="primary"
          @click="printQrCodes"
        >
          <span class="mx-2">
            Afdrukken
          </span>
        </VBtn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from '@axios'
import { computed, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

function toast(status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Data
    const loginMethod = ref({
      type: '',
    })
    const selectedLoginMethod = ref('none')
    const formObj = ref({
      valid: true,
    })
    const allowedLoginMethods = ref([
      {
        type: 'none',
        label: 'Kan niet inloggen',
        fields: [],
      },
      {
        type: 'password',
        label: 'Gebruikersnaam en wachtwoord',
        fields: [
          {
            name: 'username',
            label: 'E-mail adres',
            type: 'email',
            rules: [
              v => !!v || 'Email is verplicht',
              v => /.+@.+/.test(v) || 'Email moet een geldig emailadres zijn',
            ],
            value: null,
          },
          {
            name: 'password',
            label: 'Wachtwoord',
            type: 'password',
            rules: [
              v => !!v || 'Wachtwoord is verplicht',
            ],
            value: null,
          },
        ],
      },
      {
        type: 'qr',
        label: 'QR-code',
        fields: [],
      },
      {
        type: 'google',
        label: 'Google',
        fields: [
          {
            name: 'email',
            label: 'E-mail adres',
            type: 'email',
            rules: [
              v => !!v || 'Email is verplicht',
              v => /.+@.+/.test(v) || 'Email moet een geldig emailadres zijn',
            ],
            value: null,
          },
        ],
      },
      {
        type: 'microsoft',
        label: 'Microsoft',
        fields: [
          {
            name: 'email',
            label: 'E-mail adres',
            type: 'email',
            rules: [
              v => !!v || 'Email is verplicht',
              v => /.+@.+/.test(v) || 'Email moet een geldig emailadres zijn',
            ],
            value: null,
          },
        ],
      },
    ])

    // Computed properties
    const selectedLoginMethodObj = computed(() => allowedLoginMethods.value.find(method => method.type === selectedLoginMethod.value))

    // Functions
    const refetchData = () => {
      axios.get(`/account/${props.userData.id}/loginmethod`).then(loginMethodResponse => {
        if (loginMethodResponse.status === 200) {
          loginMethod.value = loginMethodResponse.data
          selectedLoginMethod.value = loginMethodResponse.data.type
        }
      })
    }
    refetchData()

    const saveLoginMethod = async () => {
      console.log('oi')
      console.log(formObj.value.valid)
      if (formObj.value.valid || selectedLoginMethodObj.value.fields.length === 0) {
        const data = {
          type: selectedLoginMethod.value,
          details: {},
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const field of selectedLoginMethodObj.value.fields) {
          data.details[field.name] = field.value
        }

        console.log(data)

        const response = await axios.put(`/account/${props.userData.id}/loginmethod`, data)
        if (response.status === 200) {
          toast('success', 'Inlogmethode opgeslagen', 'De inlogmethode is opgeslagen')
          refetchData()
        }
      }
    }

    // Print QR codes for selected pupils
    const printQrCodes = async () => {
      const usersWithQr = []
      usersWithQr.push(props.userData)

      const qrIds = usersWithQr.map(user => user.id)
      localStorage.removeItem('qrIds') // remove one item
      setTimeout(() => {
        localStorage.setItem('qrIds', JSON.stringify(qrIds))
        window.open('/print/qr', '_blank')
      }, 250)
    }

    return {
      loginMethod,
      selectedLoginMethod,
      formObj,
      allowedLoginMethods,
      selectedLoginMethodObj,

      refetchData,
      saveLoginMethod,
      printQrCodes,
    }
  },
}
</script>
